import React from 'react';
import SEO from '../components/seo';
import { ResetCSS } from '../common/assets/css/style';
import ErrorSec from '../containers/Error';
import ErrorImage from '../common/assets/image/Error_500.svg';

const NotFoundPage = () => (
    <>
        <SEO title="500: Server Error" />
        <ResetCSS />
        <ErrorSec
            title="Oops, something went wrong."
            imageUrl={ErrorImage}
            imageTitle="500"
            text="try to refresh this page or feel free to contact us if the problem persists."
        />
    </>
);

export default NotFoundPage;
